import { registerBlockType } from "@wordpress/blocks";

import "./style.css";

import Edit from "./edit";
import save from "./save";
import metadata from "./block.json";

registerBlockType(metadata.name, {
  title: metadata.title,
  icon: metadata.icon,
  category: metadata.category,
  attributes: {
    name: {
      type: "string",
    },
    surname: {
      type: "string",
    },
    bg_color: {
      type: "string",
			default: "#000000" 
    },
    text_color: {
      type: "string",
			default: "#000000" 
    },
    desktopCols: {
      type: "number",
      default: 2
    }
  },
  edit: Edit,
  save,
});
