import { useBlockProps, InnerBlocks } from "@wordpress/block-editor";

export default function save(props) {
  const style = {
    backgroundColor: props.attributes.bg_color,
    color: props.attributes.text_color,
    padding: 8,
  };

  const cols = Array.from(Array(props.attributes.desktopCols).keys());

  return (
    <div {...useBlockProps.save()}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        {cols.map((item) => (
          <div>
            <InnerBlocks.Content />
          </div>
        ))}
      </div>
      <div style={style}>
        {props.attributes.name} {props.attributes.surname}
      </div>
    </div>
  );
}
