import { __ } from "@wordpress/i18n";
import {
  useBlockProps,
  ColorPalette,
  InspectorControls,
  InnerBlocks,
} from "@wordpress/block-editor";
import { RangeControl, PanelBody } from "@wordpress/components";
import { useEffect, useState } from "@wordpress/element";

function Posts() {
  const [data, setData] = useState([]); // Estado para armazenar os posts
  const [loading, setLoading] = useState(true); // Estado para o status de carregamento

  useEffect(() => {
    // Função para buscar os dados
    const fetchPosts = async () => {
      try {
        const response = await fetch(BlockBlockData.apiUrl);
        if (!response.ok) {
          throw new Error("Erro ao buscar posts");
        }
        const posts = await response.json();
        setData(posts); // Atualiza o estado com os posts
      } catch (error) {
        console.error("Erro:", error);
      } finally {
        setLoading(false); // Finaliza o carregamento
      }
    };

    fetchPosts();
  }, [data]); // O array vazio garante que o useEffect execute apenas uma vez

  if (loading) {
    return <div>Carregando posts...</div>;
  }

  if (data.length > 0) {
    return (
      <div>
        {data.map((item) => (
          <div key={item.id}>{item.slug}</div>
        ))}
      </div>
    );
  }

  return <div>Nothing</div>;
}

export default function Edit(props) {
  const [data, setData] = useState([]); // Estado para armazenar os posts
  const [loading, setLoading] = useState(true); // Estado para o status de carregamento

  useEffect(() => {
    // Função para buscar os dados
    const fetchPosts = async () => {
      try {
        const response = await fetch(BlockBlockData.apiUrl);
        if (!response.ok) {
          throw new Error("Erro ao buscar posts");
        }
        const posts = await response.json();
        setData(posts); // Atualiza o estado com os posts
      } catch (error) {
        console.error("Erro:", error);
      } finally {
        setLoading(false); // Finaliza o carregamento
      }
    };

    fetchPosts();
  }, [data]); // O array vazio garante que o useEffect execute apenas uma vez

  function updateName(event) {
    props.setAttributes({ name: event.target.value });
  }

  function updateSurname(event) {
    props.setAttributes({ surname: event.target.value });
  }

  const onChangeBGColor = (hexColor) => {
    props.setAttributes({ bg_color: hexColor });
  };

  const onChangeTextColor = (hexColor) => {
    props.setAttributes({ text_color: hexColor });
  };

  const onNumberChange = (val) => {
    props.setAttributes({ desktopCols: val });
  };

  const cols = Array.from(Array(props.attributes.desktopCols).keys());

  return (
    <div {...useBlockProps()}>
      <InspectorControls key="setting">
        <PanelBody title="My Block Settings" initialOpen>
          <div>
            <fieldset>
              <RangeControl
                label="Columns"
                step={1}
                withInputField={false}
                separatorType="fullWidth"
                isShiftStepEnabled={true}
                separatorTypes={"none"}
                type="stepper"
                value={props.attributes.desktopCols}
                onChange={(v) => props.setAttributes({ desktopCols: v })}
                min={1}
                max={12}
              />
            </fieldset>
            <fieldset>
              <legend className="blocks-base-control__label">
                {__("Background color", "block-development-examples")}
              </legend>
              <ColorPalette
                onChange={onChangeBGColor}
                value={props.attributes.bg_color}
              />
            </fieldset>
            <fieldset>
              <legend className="blocks-base-control__label">
                {__("Text color", "block-development-examples")}
              </legend>
              <ColorPalette
                onChange={onChangeTextColor}
                value={props.attributes.text_color}
              />
            </fieldset>
          </div>
        </PanelBody>
      </InspectorControls>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        {data.map((post, index) => (
          <div key={post.id} className="post-item">
            <InnerBlocks
              allowedBlocks={["core/heading", "core/paragraph", "core/image"]}
              template={[
                ["core/heading", { content: post.title.rendered }],
                ["core/paragraph", { content: post.slug }],
              ]}
              templateLock={false} // Permitir edição livre
              context={{
                postId: post.id,
                postTitle: post.title.rendered,
                postSlug: post.slug,
              }}
            />
            <div>{post.slug}</div>
          </div>
        ))}
      </div>
      <input value={props.attributes.name} onChange={updateName} />
      <input value={props.attributes.surname} onChange={updateSurname} />
    </div>
  );
}
